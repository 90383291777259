/* global $ */
class App
{
    constructor()
    {
        $('body').css('opacity', 1);
        this.getBootstrap();
        this.initFontAwesome();
        this.initBannerSlide();
        this.initMatrixContent();
        this.initStickyMenu();
        this.initNavOpen();
        this.initForms();
        console.log('App initialized');
    }

    getBootstrap()
    {
        if (!this.bootstrap) {
            this.bootstrap = import(/* webpackChunkName: "bootstrap" */ 'bootstrap');
        }
        return this.bootstrap;
    }

    initFontAwesome()
    {
        if ($('.fa-brands, .fa-solid, .fa-regular, .fa-light, .fa-thin, .fa-duotone, .fa').length) {
            import(/* webpackChunkName: "fontawesome" */ './components/fontawesome');
        }
    }
    initBannerSlide() {
        if ($(".banner-slider .banner-slide").length > 1) {
            import(/* webpackChunkName: "slick" */ './components/slick').then(() => {
                $('.banner-slider').slick({
                    autoplay: true,
                    autoplaySpeed: 5000,
                    speed: 800,
                    rows: 0
                });
            });
        }
    }

    initMatrixContent()
    {
        if ($('.matrix-content').length) {
            import(/* webpackChunkName: "matrixContent" */ './components/matrixContent').then(matrixContent => {
                new matrixContent.MatrixContent;
            });
        }
    }
    initStickyMenu() {
        var stickyNav = function() {
            var scrollTop = $(window).scrollTop() + 30;
            if (scrollTop > 250) {
                $('#header-top').addClass('position-fixed top-0');
            } else {
                $('#header-top').removeClass('position-fixed top-0');
            }
        };
        stickyNav();
        $(window).scroll(function() {
            stickyNav();
        });
    }

    initNavOpen() {
        var navOpen = function() {
            if ($('#navbar-toggler').hasClass('collapsed')) {
                $('#header').removeClass('expanded');
                $('#booknow-btn-top').removeClass('d-none');
                $('body').removeClass('overflow-hidden');
            } else {
                $('#header').addClass('expanded');
                $('#booknow-btn-top').addClass('d-none');
                $('body').addClass('overflow-hidden');
            }
        };
        navOpen();
        $('#navbar-toggler').click(function() {
            navOpen();
        });
    }

    initForms()
    {
        if ($('form').length) {
            import(/* webpackChunkName: "form" */ './components/form').then(chunk => {
                $.each($('form.js-validate'), (i, elem) => {
                    new chunk.Form(elem.id);
                });
            });
        }
    }
}

export default App;